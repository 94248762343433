import { graphql } from 'gatsby'
import React from 'react'

import '../../styles/global.css'
import { ContentMain } from '../../components/common/content-main'
import { ContentSubPage } from '../../components/common/content-sub-page'
import { HeaderTitle } from '../../components/common/header-title'
import { VideoPlayer } from '../../components/common/video-player'
import { CookiesBox } from '../../components/cookies-box'
import { Footer } from '../../components/footer'
import { Navigation } from '../../components/navigation'
import { SEO } from '../../components/seo'

const BlogPage = () => (
  <>
    <SEO title="Nastavení uživatelského prostředí aplikace" />
    <div className="flex flex-col h-screen justify-between">
      <Navigation />
      <ContentMain>
        <HeaderTitle title="Nastavení uživatelského prostředí aplikace" />
        <ContentSubPage>
          <p>
            Jak si nastavit váš Flagis účet? Jak přidat fotku či avatara,
            kalendář, změnit heslo či barevný motiv? Je to snadné! Základním
            nastavením v aplikaci Flagis vás provede Filip Bartek.
          </p>
          <br />
          <VideoPlayer url="https://www.youtube.com/watch?v=OZslDXvrh9Q" />
        </ContentSubPage>
      </ContentMain>
      <Footer />
    </div>
    <CookiesBox />
  </>
)

export default BlogPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
